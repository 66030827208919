import React from "react"

const Header = () => {
    return (
        <div>
            <h1 className="logotext">Kyle Vickers</h1>
        </div>

    )
}

export default Header
